import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { EMPLOYEE_COMPETENCES_URL_PATH, GET_EMPLOYEE_COMPETENCES_KEY } from '../types';

export const PUT_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY = 'putEmployeeCompetencesAPI';

const updateEmployeeCompetence = ({
  competenceId,
  value,
}: {
  competenceId?: number;
  value?: number;
}) =>
  putFetch({
    path: EMPLOYEE_COMPETENCES_URL_PATH,
    key: PUT_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY,
    body: JSON.stringify({ ID: competenceId, Value: value }),
  });

export const useUpdateEmployeeCompetence = () => {
  const queryClient = useQueryClient();

  return useMutation(updateEmployeeCompetence, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_EMPLOYEE_COMPETENCES_KEY]);
    },
  });
};
