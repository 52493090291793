import { IEntitiesResponse, IPropertiesResponse } from 'src/apis/types/responseBase';

export interface IEmployees extends IEntitiesResponse<IEmployeesProperties> {}
export interface IEmployee extends IPropertiesResponse<IEmployeesProperties> {}

export interface IEmployeesProperties {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  initials?: string;
  email?: string;
  departmentId?: number;
  departmentName?: string;
  jobTitle?: string;
  managerName?: string;
  managerId?: number;
  managerInitials?: string;
  phone?: string;
  hourlyRate?: string;
  hourlyRateId?: number;
  internalCost?: string;
  internalCostId?: number;
  salaryGroup?: string;
  salaryGroupId?: number;
  normalWorkingTime?: string;
  normalWorkingTimeId?: number;
  employmentType?: string;
  employmentTypeId?: number;
  publicHolidayCalendar?: string;
  publicHolidayCalendarId?: number;
  employmentStartDate?: string;
  isActive?: boolean;
  competences?: Competence[];
}

export interface Competence {
  category?: string;
  id?: string;
  items: CompetenceItem[];
}

interface CompetenceItem {
  id?: string;
  name?: string;
  level?: number;
}

export const EMPLOYEES_URL_PATH = '/api/v2/employees';
export const EMPLOYEES_MSW_STATUS_KEY = 'employeesAPI';

export const EMPLOYEES_KEY = 'EMPLOYEES';
