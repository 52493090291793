import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFetch } from 'src/utils/fetcher';
import {
  EMPLOYEE_COMPETENCES_URL_PATH,
  GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY,
  GET_EMPLOYEE_COMPETENCES_KEY,
} from '../types';

export const DELETE_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY = 'deleteEmployeeCompetences';
export const DELETE_ALL_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY = 'deleteAllEmployeeCompetences';

export const DELETE_EMPLOYEE_COMPETENCES_KEY = 'DELETE_EMPLOYEE_COMPETENCES';
export const DELETE_ALL_EMPLOYEE_COMPETENCES_KEY = 'DELETE_ALL_EMPLOYEE_COMPETENCES';

const deleteEmployeeCompetence = (itemId?: number) =>
  deleteFetch({
    path: EMPLOYEE_COMPETENCES_URL_PATH,
    key: DELETE_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY,
    itemId: `/${itemId}`,
  });

export const useDeleteEmployeeCompetence = () => {
  const qc = useQueryClient();

  return useMutation(deleteEmployeeCompetence, {
    onSuccess: () => {
      qc.invalidateQueries([GET_EMPLOYEE_COMPETENCES_KEY]);
      qc.invalidateQueries([GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY]);
    },
  });
};

// if you pass in groupId, it will delete all employee competences for that group
const deleteAllEmployeeCompetences = ({ userId, groupId }: { userId?: number; groupId?: number }) =>
  deleteFetch({
    path: `${EMPLOYEE_COMPETENCES_URL_PATH}/bulk`,
    key: DELETE_ALL_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY,
    itemId: `/${userId}${groupId ? `?groupId=${groupId}` : ''}`,
  });

export const useDeleteAllEmployeeCompetence = () => {
  const qc = useQueryClient();

  return useMutation(deleteAllEmployeeCompetences, {
    onSuccess: () => {
      qc.invalidateQueries([GET_EMPLOYEE_COMPETENCES_KEY]);
      qc.invalidateQueries([GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY]);
    },
  });
};
