import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { usePostCompetence, useUpdateCompetence } from 'src/apis/competencesAPI';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  ToastifyAlert,
} from 'src/components/mui-components';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
// Something is messing up the types in our custom Autocomplete component
// so we need to import the original from MUI
import Autocomplete from '@mui/material/Autocomplete';

import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { stringToPascal } from 'src/utils/string';
import { useGetCompetenceGroups } from 'src/apis/competenceGroupAPI';
import { ICompetenceGroupsDatum } from 'src/apis/competenceGroupAPI/types';
import { IEntities } from 'src/apis/types/responseBase';
import { DialogProps } from '../types';

const getCategoryId = (
  categories: IEntities<ICompetenceGroupsDatum>[] | undefined,
  inputValue: string | undefined,
) =>
  categories?.find(({ properties }) => properties?.groupName === inputValue)?.properties?.groupId;

export const NewCompetenceDialog = ({
  setIsOpen,
  setRowInfo,
  rowInfo,
  isEditing,
  setIsEditing,
  ...rest
}: DialogProps) => {
  const { t } = useTranslation('systemAdministrationEmployeesCompetences');

  // Data
  const { categories } = useGetCompetenceGroups();

  const { mutate: postCompetence } = usePostCompetence();
  const { mutate: updateCompetence } = useUpdateCompetence();

  // Form handling
  const { name = '', category = '', isActive = true } = rowInfo || {};

  const formSchema = z.object({
    name: z.string().min(1, { message: t('modals.CompetenceCreateInputNameValidationError') }),
    category: z
      .string()
      .min(1, { message: t('modals.CompetenceCreateInputCategoryValidationError') }),
    isActive: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      name,
      category: categories?.find(({ properties }) => properties?.groupId === category)?.properties
        ?.groupName,
      isActive: isActive ?? true,
    },
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  // Actions
  const cleanUp = () => {
    setIsOpen(false);
    reset();
    if (setRowInfo) setRowInfo({});
    if (setIsEditing) setIsEditing(false);
  };

  const handleCreate = () => {
    postCompetence(
      {
        name: form.getValues('name'),
        category: getCategoryId(categories, form.getValues('category')),
        isActive: form.getValues('isActive'),
      },
      {
        onSuccess: () => {
          toast(
            <ToastifyAlert
              title={t('modals.CompetenceCreatedToastTitle', {
                COMPETENCE_NAME: form.getValues('name'),
              })}
              description={t('modals.CompetenceCreatedToastDescription', {
                CATEGORY_NAME: form.getValues('category'),
              })}
              color="success"
              data-automation-id="CreateCompetenceDialogToastSuccess"
            />,
            { autoClose: 4000, closeButton: false },
          );
          cleanUp();
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('modals.CompetenceCreateToastTitleError', {
                COMPETENCE_NAME: form.getValues('name'),
              })}
              description={`${error}`}
              color="error"
              data-automation-id="CreateCompetenceDialogToastError"
            />,
            { closeButton: true },
          );
        },
      },
    );
  };

  const handleEdit = () => {
    updateCompetence(
      {
        id: rowInfo?.apiId,
        name: form.getValues('name'),
        isActive: form.getValues('isActive'),
        groupId: getCategoryId(categories, form.getValues('category')),
      },
      {
        onSuccess: () => {
          toast(
            <ToastifyAlert
              title={t('modals.CompetenceEditToastTitle', {
                COMPETENCE_NAME: form.getValues('name'),
              })}
              color="success"
              data-automation-id="EditCompetenceDialogToastSuccess"
            />,
            { autoClose: 4000, closeButton: false },
          );
          cleanUp();
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('modals.CompetenceEditToastTitleError', {
                COMPETENCE_NAME: form.getValues('name'),
              })}
              description={`${error}`}
              color="error"
              data-automation-id="EditCompetenceDialogToastSuccess"
            />,
            { closeButton: true },
          );
        },
      },
    );
  };

  const handleCancel = () => {
    cleanUp();
  };

  return (
    <Dialog
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      {...rest}
      data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceDialog`}
    >
      <form onSubmit={handleSubmit(isEditing ? handleEdit : handleCreate)}>
        <DialogTitle>
          {isEditing ? t('modals.CompetenceEditHeader') : t('modals.CompetenceCreateHeader')}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <Stack gap={2} sx={{ pt: 1 }}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceDialogName`}
                  label={t('modals.CompetenceCreateInputNameLabelText')}
                  variant="outlined"
                  ariaLabel={t('modals.CompetenceCreateInputNameLabelText')}
                  required
                  fullWidth
                  error={!!errors.name?.message?.toString()}
                  helperText={errors.name?.message?.toString()}
                  {...field}
                />
              )}
            />
            <Controller
              name="category"
              control={control}
              // https://youtu.be/5UhpJHcA6Oc?feature=shared&t=402
              // example of how to use rules with react-hook-form
              rules={{ required: true }}
              render={({ field }) => {
                const { onChange, value, ref } = field;
                return (
                  <Autocomplete
                    options={categories?.map(({ properties }) => properties?.groupName || '') ?? []}
                    value={value}
                    renderOption={(props, option, state) => (
                      <li
                        data-automation-id={`${
                          isEditing ? 'Edit' : 'Create'
                        }CompetenceDialogAutocompleteCategoryItem${stringToPascal(option ?? '')}`}
                        {...props}
                        key={`${option}-${state.index}`}
                      >
                        <HighlightMatchingText name={option} matchName={state.inputValue} />
                      </li>
                    )}
                    fullWidth
                    handleHomeEndKeys
                    onChange={(event, newValue) => {
                      onChange(newValue || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('modals.CompetenceCreateInputCategoryLabelText')}
                        ariaLabel={t('modals.CompetenceCreateInputCategoryLabelText')}
                        variant="outlined"
                        error={!!errors.category?.message?.toString()}
                        helperText={errors.category?.message?.toString()}
                        required
                        data-automation-id={`${
                          isEditing ? 'Edit' : 'Create'
                        }CompetenceDialogAutocompleteCategory`}
                        inputRef={ref}
                        {...field}
                      />
                    )}
                  />
                );
              }}
            />
            <FormControlLabel
              control={
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                      data-automation-id={`${
                        isEditing ? 'Edit' : 'Create'
                      }CompetenceDialogCheckboxIsActive`}
                    />
                  )}
                />
              }
              label={t('modals.CompetenceCreateInputActiveLabelText')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCancel}
            data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceDialogButtonCancel`}
          >
            {t('modals.CreateButtonCancelText')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid}
            // onClick={handleSubmit(isEditing ? handleEdit : handleCreate)}
            data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceDialogButtonSave`}
          >
            {t('modals.CreateButtonSaveText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
