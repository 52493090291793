export const competencesFullResult = {
  Properties: {
    TotalRecord: '9',
    TotalPage: '1',
    PageNumber: '1',
  },
  Entities: [
    {
      Properties: {
        GroupId: 1,
        GroupName: 'Language Skills',
        Items: [
          {
            Id: 11,
            Name: 'Svenska',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 15,
            Name: 'Test DRO',
            IsActive: true,
            CanBeDeleted: true,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 2,
        GroupName: 'Project management',
        Items: [
          {
            Id: 23,
            Name: 'Agile / Scrum',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 24,
            Name: 'Financial management',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 22,
            Name: 'Prince2',
            IsActive: true,
            CanBeDeleted: true,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 3,
        GroupName: 'Development',
        Items: [],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/competences/grouped',
      Rel: 'self',
    },
  ],
};
