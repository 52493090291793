import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IEntities } from 'src/apis/types/responseBase';
import {
  IEmployees,
  IEmployeesProperties,
  IEmployee,
  EMPLOYEES_URL_PATH,
  EMPLOYEES_MSW_STATUS_KEY,
  EMPLOYEES_KEY,
} from '../types';

const employeeFetch = (itemId?: string): Promise<IEmployee> =>
  getFetch({ path: EMPLOYEES_URL_PATH, itemId: `/${itemId}`, key: EMPLOYEES_MSW_STATUS_KEY });

const employeesFetch = (): Promise<IEmployees> =>
  getFetch({ path: EMPLOYEES_URL_PATH, key: EMPLOYEES_MSW_STATUS_KEY });

export const useGetEmployees = () => {
  const { data, ...restProps } = useQuery([EMPLOYEES_KEY], employeesFetch);

  const massagedData = (rows: IEntities<IEmployeesProperties>[] | undefined) => {
    if (!rows) {
      return [];
    }

    const massagedRows: IEmployeesProperties[] = [];

    rows.forEach((row) => {
      massagedRows.push(row.properties || {});
    });

    return massagedRows;
  };

  return {
    employees: massagedData(data?.entities),
    ...restProps,
  };
};

export const useGetEmployee = (itemId?: string) => {
  const { data, ...restProps } = useQuery([EMPLOYEES_KEY, itemId], () => employeeFetch(itemId));

  return {
    data: data?.properties,
    ...restProps,
  };
};
