export const competencesByGroupFullResult = {
  Properties: {
    TotalRecord: '13',
    TotalPage: '2',
    PageNumber: '1',
  },
  Entities: [
    {
      Properties: {
        GroupId: 1,
        GroupName: 'Language Skills',
        GroupCanBeDeleted: false,
        Items: [
          {
            Id: 1,
            Name: 'Danish',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 13,
            Name: 'English',
            IsActive: true,
            CanBeDeleted: true,
          },
          {
            Id: 12,
            Name: 'Latvian',
            IsActive: true,
            CanBeDeleted: true,
          },
          {
            Id: 5,
            Name: 'Swedish',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 6,
            Name: 'German',
            IsActive: true,
            CanBeDeleted: false,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 2,
        GroupName: 'Project Management',
        GroupCanBeDeleted: false,
        Items: [
          {
            Id: 3,
            Name: 'Agile / Scrum',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 2,
            Name: 'Prince2',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 4,
            Name: 'Finance control',
            IsActive: true,
            CanBeDeleted: false,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 3,
        GroupName: 'Development',
        GroupCanBeDeleted: true,
      },
    },
    {
      Properties: {
        GroupId: 4,
        GroupName: 'Change Management Tools',
        GroupCanBeDeleted: false,
        Items: [
          {
            Id: 11,
            Name: 'Change Management Mastering',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 10,
            Name: 'Lego Serious Play',
            IsActive: true,
            CanBeDeleted: false,
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'https://app8.timelog.com/npo_sandbox/api/v2/competences/by-group',
      Rel: 'self',
    },
  ],
};
