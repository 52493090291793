import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteCompetence, useGetCompetences } from 'src/apis/competencesAPI';
import { IConvertedRow, TCompetenceState } from 'src/apis/competencesAPI/types';
import {
  Box,
  Button,
  DataGrid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from 'src/components/mui-components';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

import { CheckCircle, Delete, Edit } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
import { visuallyHidden } from '@mui/utils';
import { GridColDef, GridEventListener } from '@mui/x-data-grid-pro';

import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { useDeleteCompetenceGroups, useGetCompetenceGroups } from 'src/apis/competenceGroupAPI';
import { useDebounce } from 'use-debounce';
import { DeleteDialog, SystemAdminContentWrapper } from '../../components';
import { NewCompetenceCategoryDialog, NewCompetenceDialog, Toolbar } from './components';

// Density selector and export button
const slots = {
  toolbar: Toolbar,
};

export const Competences = () => {
  const { t } = useTranslation('systemAdministrationEmployeesCompetences');

  // Filters
  const [viewStatus, setViewStatus] = useLocalStorage<TCompetenceState>(
    'system-administration-competences-status-value',
    'all',
  );

  const handleViewChange = (event: SelectChangeEvent) => {
    setViewStatus(event.target.value as TCompetenceState);
  };

  const [viewCategory, setViewCategory] = useLocalStorage<string>(
    'system-administration-competences-category-value',
    '-1',
  );

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setViewCategory(event.target.value);
  };

  const [queryString, setQueryString] = useLocalStorage<string>(
    'system-administration-competences-query-value',
    '',
  );
  const [debouncedQueryString] = useDebounce(queryString, 250);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQueryString(event.target.value);
  };

  // States
  const [competenceCategoryIsOpen, setCompetenceCategoryIsOpen] = useState(false);
  const [competenceIsOpen, setCompetenceIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Data
  const initialRowInfo: IConvertedRow = useMemo(
    () => ({
      id: '',
      apiId: -1,
      name: '',
      category: -1,
      isActive: undefined,
      canBeDeleted: undefined,
      items: [],
    }),
    [],
  );
  const [rowInfo, setRowInfo] = useState<IConvertedRow>(initialRowInfo);

  const { rows, isLoading } = useGetCompetences();

  const filteredData = useMemo(() => {
    if (!rows) return [];
    const filteredRows = rows.filter((item) => {
      const isQueryMatching =
        item.name?.toLowerCase().includes(debouncedQueryString.toLowerCase()) ||
        item.items?.some((i) => i.name?.toLowerCase().includes(debouncedQueryString.toLowerCase()));
      const isCategoryMatching =
        viewCategory === '-1' ||
        item.category === parseInt(viewCategory, 10) ||
        (item.type === 'category' && item.apiId === parseInt(viewCategory, 10));
      const isStatusMatching =
        viewStatus === 'all' ||
        item.isActive === (viewStatus === 'active') ||
        item.items?.some((i) => i.isActive === (viewStatus === 'active'));

      return isQueryMatching && isCategoryMatching && isStatusMatching;
    });

    return filteredRows;
  }, [rows, debouncedQueryString, viewCategory, viewStatus]);

  const { categories } = useGetCompetenceGroups();

  const { mutate: deleteCompetence } = useDeleteCompetence();
  const { mutate: deleteCompetenceGroup } = useDeleteCompetenceGroups();

  // Actions
  const handleDelete = (row: IConvertedRow) => {
    setRowInfo(row);
    setDeleteIsOpen(true);
  };

  useEffect(() => {
    if (!deleteIsOpen) {
      setRowInfo(initialRowInfo);
    }
  }, [deleteIsOpen, initialRowInfo]);

  const handleEdit = (row: IConvertedRow) => {
    setIsEditing(true);
    setRowInfo(row);
    if (row.category) {
      setCompetenceIsOpen(true);
    } else {
      setCompetenceCategoryIsOpen(true);
    }
  };

  const handleDoubleClickEvent: GridEventListener<'cellDoubleClick'> = (params, event) => {
    event.preventDefault();

    if (params.field === 'name') {
      handleEdit(params.row as IConvertedRow);
    }
  };

  // Table preparations
  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      minWidth: 200,
      headerName: t('TableColumnHeaderTextName'),
      renderCell: ({ row }) => (
        // userSelect: 'none' is used to prevent text selection on double click
        <Typography fontWeight={row.category ? 400 : 600} sx={{ userSelect: 'none' }}>
          <HighlightMatchingText name={row.name} matchName={queryString} />
        </Typography>
      ),
      sortable: false,
    },
    {
      field: 'active',
      type: 'string',
      width: 100,
      headerName: t('TableColumnHeaderTextStatus'),
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) =>
        row.isActive ? (
          <Tooltip title={t('CheckmarkIconTooltipText')}>
            <CheckCircle color="success" />
          </Tooltip>
        ) : (
          ''
        ),
      sortable: false,
    },
    {
      field: 'actions',
      width: 100,
      renderHeader: () => (
        <Box sx={visuallyHidden}>{t('TableColumnHeaderScreenReaderTextActions')}</Box>
      ),
      align: 'center',
      renderCell: ({ row }) => {
        const disabled = row.items?.length > 0 || !row.canBeDeleted;
        const isCategory = row.type === 'category';
        const disabledText = isCategory
          ? t('CategoryCanNotBeDeletedReasonTooltipText')
          : t('CompetenceCanNotBeDeletedReasonTooltipText');
        return (
          <Stack direction="row">
            <IconButton
              title={t('EditTooltipText', { COMPETENCE_NAME: row.name })}
              onClick={() => handleEdit(row)}
              data-automation-id={`${isCategory ? 'Category' : 'Competences'}EditButton${
                row.apiId
              }`}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              disabled={disabled}
              title={
                disabled ? disabledText : t('DeleteTooltipText', { COMPETENCE_NAME: row.name })
              }
              onClick={() => handleDelete(row)}
              data-automation-id={`${isCategory ? 'Category' : 'Competences'}DeleteButton${
                row.apiId
              }`}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
      sortable: false,
    },
  ];

  return (
    <SystemAdminContentWrapper header={t('PageHeader')} description={t('PageDescription')}>
      <Stack gap={2}>
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" flexWrap="wrap">
            <TextField
              value={queryString}
              onChange={onSearchChange}
              label={t('SearchInputLabelText')}
              ariaLabel={t('SearchInputLabelText')}
              data-automation-id="CompetencesSearchInput"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Select
              value={viewStatus}
              size="small"
              label={t('ViewOptionStatusLabel')}
              onChange={handleViewChange}
              autoWidth
              data-automation-id="CompetencesStatusSelect"
            >
              <MenuItem value="all" data-automation-id="CompetencesStatusItemAll">
                {t('ViewOptionStatusAllText')}
              </MenuItem>
              <MenuItem value="active" data-automation-id="CompetencesStatusItemActive">
                {t('ViewOptionStatusActiveText')}
              </MenuItem>
              <MenuItem value="inactive" data-automation-id="CompetencesStatusItemInactive">
                {t('ViewOptionStatusInActiveText')}
              </MenuItem>
            </Select>

            <Select
              value={viewCategory}
              size="small"
              label={t('ViewOptionCategoryLabel')}
              onChange={handleCategoryChange}
              autoWidth
              data-automation-id="CompetencesCategorySelect"
            >
              <MenuItem value="-1">{t('ViewOptionCategoryAllCategories')}</MenuItem>
              {categories?.map(({ properties: categoriesProperties }) => (
                <MenuItem
                  key={`filter-category-option-${categoriesProperties?.groupId}`}
                  data-automation-id={`CompetencesCategorySelectItem${categoriesProperties?.groupId}`}
                  value={categoriesProperties?.groupId}
                >
                  {categoriesProperties?.groupName}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack direction="row" flexWrap="wrap" spacing={1}>
            <Button
              size="small"
              variant="contained"
              onClick={() => setCompetenceCategoryIsOpen(true)}
              data-automation-id="SystemAdministrationCreateCompetenceCategoryButton"
            >
              {t('CreateNewCompetenceCategoryButtonText')}
            </Button>

            <Button
              variant="contained"
              onClick={() => setCompetenceIsOpen(true)}
              data-automation-id="SystemAdministrationCreateCompetenceButton"
            >
              {t('CreateNewCompetenceButtonText')}
            </Button>
          </Stack>
        </Stack>

        <div style={{ width: '100%' }}>
          <DataGrid
            disableColumnMenu
            disableColumnTopBorder
            disableRowSelectionOnClick
            disableColumnResize
            autoHeight
            columns={columns}
            rows={filteredData}
            slots={slots}
            loading={isLoading}
            pagination
            pageSizeOptions={[20, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            onCellDoubleClick={handleDoubleClickEvent}
            data-automation-id="CompetencesDataGrid"
          />
        </div>

        {/* Dialogs */}
        {deleteIsOpen && (
          <DeleteDialog
            open={deleteIsOpen}
            setIsOpen={setDeleteIsOpen}
            itemId={rowInfo.apiId ?? -1}
            name={rowInfo.name ?? ''}
            deleteFunction={
              rowInfo.type === 'competence' ? deleteCompetence : deleteCompetenceGroup
            }
          />
        )}

        {competenceCategoryIsOpen && (
          <NewCompetenceCategoryDialog
            open={competenceCategoryIsOpen}
            setIsOpen={setCompetenceCategoryIsOpen}
            rowInfo={rowInfo}
            setRowInfo={setRowInfo}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        )}

        {competenceIsOpen && (
          <NewCompetenceDialog
            open={competenceIsOpen}
            setIsOpen={setCompetenceIsOpen}
            rowInfo={rowInfo}
            setRowInfo={setRowInfo}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        )}
      </Stack>
    </SystemAdminContentWrapper>
  );
};
