import { ChangeEvent, FormEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  IconButton,
  MenuItem,
  TextField,
  ToastifyAlert,
  Typography,
} from 'src/components/mui-components';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { DialogProps } from 'src/screens/SystemAdministration/Employees/Competences/components/types';
import { useDebounce } from 'use-debounce';
import { useConfirm } from 'material-ui-confirm';

import { FilterList, Search } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  Menu,
  Skeleton,
  Stack,
  Switch,
} from '@mui/material';

import { useGetCompetenceGroups } from 'src/apis/competenceGroupAPI';
import {
  usePostEmployeeCompetence,
  useGetAvailableEmployeeCompetences,
} from 'src/apis/employeeCompetencesAPI';
import { IAddCompetenceItems } from 'src/apis/employeeCompetencesAPI/types';
import { AddCompetenceItem } from './components';

export interface ICheckedCompetence {
  id?: number;
  level?: number;
}

export const AddCompetenceDialog = ({ setIsOpen, userId, ...rest }: DialogProps) => {
  const confirm = useConfirm();
  const { t } = useTranslation('employeeCompetences');
  const {
    categories,
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
  } = useGetCompetenceGroups();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedQuery] = useDebounce(searchQuery, 250);

  const [checkedCategories, setCheckedCategories] = useState<(number | undefined)[]>([]);

  useEffect(() => {
    if (categories && categories?.length > 0 && checkedCategories.length === 0) {
      setCheckedCategories(categories?.map(({ properties }) => properties?.groupId));
    }
  }, [categories, setCheckedCategories, checkedCategories]);

  const initialValue = 1;

  // for checking all the categories
  const [initialCategories] = useState(categories?.map(({ properties }) => properties?.groupId));

  const {
    nestedData: availableCompetences,
    isLoading: availableCompetencesIsLoading,
    isError: availableCompetencesIsError,
  } = useGetAvailableEmployeeCompetences(userId);

  const { mutate: addCompetence } = usePostEmployeeCompetence();

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleToggleCategories = (value: number | undefined) => () => {
    const currentIndex = checkedCategories.indexOf(value);
    const newChecked = [...checkedCategories];

    if (checkedCategories.length === 1 && currentIndex !== -1) return;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedCategories(newChecked);
  };

  const [checkedCompetences, setCheckedCompetences] = useState<IAddCompetenceItems[]>([]);

  const handleToggleCompetences = (id: number) => () => {
    const isAlreadyChecked = checkedCompetences.some((i) => i.competenceId === id);
    let newChecked = [...checkedCompetences];

    if (!isAlreadyChecked) {
      newChecked = [...checkedCompetences, { competenceId: id, value: initialValue }];
    } else {
      newChecked = newChecked.filter((i) => i.competenceId !== id);
    }

    setCheckedCompetences(newChecked);
  };

  const cleanUp = () => {
    setIsOpen(false);
    setSearchQuery('');
    setCheckedCompetences([]);
  };

  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuIsOpen = Boolean(contextMenuAnchorEl);
  const handleContextMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setContextMenuAnchorEl(event.currentTarget);
  };
  const handleContextMenuClose = () => {
    setContextMenuAnchorEl(null);
  };

  const allCategoriesAreChecked = checkedCategories.length === categories?.length;

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    addCompetence(
      {
        userId: parseInt(userId ?? '0', 10),
        employeeCompetences: checkedCompetences,
      },
      {
        onSuccess: () => {
          toast(
            <ToastifyAlert
              title={t('modals.AddCompetenceToastTitle', {
                COMPETENCE_COUNT: checkedCompetences.length,
              })}
              color="success"
              data-automation-id="AddCompetenceDialogToastSuccess"
            />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
          cleanUp();
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('modals.AddCompetenceToastTitleError')}
              description={`${error}`}
              color="error"
              data-automation-id="AddCompetenceDialogToastError"
            />,
            { closeButton: true },
          );
        },
      },
    );
  };

  const handleClose = () => {
    if (checkedCompetences.length < 1) {
      cleanUp();
    } else {
      confirm({
        title: t('modals.AddCompetenceCloseWarningTitle'),
        description: t('modals.AddCompetenceCloseWarningText'),
        dialogProps: {
          maxWidth: 'xs',
        },
        confirmationButtonProps: {
          variant: 'contained',
          size: 'small',
        },
        cancellationButtonProps: {
          variant: 'outlined',
          size: 'small',
        },
      }).then(() => {
        cleanUp();
      });
    }
  };

  const selectedCategoriesLabelText =
    checkedCategories.length > 1
      ? t('modals.AddCompetenceInputSearchMultipleCategoryLabelText', {
          CATEGORY_NAME: categories?.find(
            ({ properties }) => checkedCategories[0] === properties?.groupId,
          )?.properties?.groupName,
          CATEGORIES_COUNT: checkedCategories.length - 1,
        })
      : t('modals.AddCompetenceInputSearchOneCategoryLabelText', {
          CATEGORY_NAME: categories?.find(
            ({ properties }) => checkedCategories[0] === properties?.groupId,
          )?.properties?.groupName,
        });

  // Filtering data on the client side
  const filteredData = useMemo(() => {
    if (!availableCompetences) return [];
    const filteredRows = availableCompetences
      .map(({ groupId, groupName, items }) => ({
        groupId,
        groupName,
        items: items?.filter((i) =>
          i.competenceName?.toLowerCase().includes(debouncedQuery.toLowerCase()),
        ),
      }))
      .filter((item) => {
        const isQueryMatching = item?.items?.filter((i) =>
          i.competenceName?.toLowerCase().includes(debouncedQuery.toLowerCase()),
        );

        const isCategoryMatching = checkedCategories.includes(item?.groupId);
        return isQueryMatching && isCategoryMatching;
      });

    return filteredRows;
  }, [availableCompetences, debouncedQuery, checkedCategories]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      {...rest}
      onClose={handleClose}
      data-automation-id="AddCompetenceDialog"
      PaperProps={{
        component: 'form',
        onSubmit: handleSave,
        method: 'post',
      }}
    >
      <DialogTitle component={Stack}>
        <Stack gap={2}>
          <Typography variant="h2">{t('modals.AddCompetenceHeader')}</Typography>
          <Stack direction="row" alignItems="center">
            <TextField
              size="small"
              label={
                allCategoriesAreChecked
                  ? t('modals.AddCompetenceInputSearchAllLabelText')
                  : selectedCategoriesLabelText
              }
              ariaLabel="Search"
              data-automation-id="AddCompetenceDialogSearchInput"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={searchQuery}
              onChange={handleSearchQueryChange}
              onKeyDown={(e) => {
                // This is prevent the form from submitting when pressing enter while searching
                if (e.key === 'Enter') e.preventDefault();
              }}
            />

            <ResponseHandler isLoading={categoriesIsLoading} isError={categoriesIsError}>
              <>
                <IconButton
                  title={t('modals.AddCompetenceFilterCategoriesButtonTooltipText')}
                  id="category-context-menu-button"
                  aria-controls="category-context-menu"
                  aria-haspopup="true"
                  aria-expanded={contextMenuIsOpen ? 'true' : 'false'}
                  onClick={handleContextMenuClick}
                  data-automation-id="AddCompetenceDialogFilterButton"
                >
                  <Badge
                    color="primary"
                    invisible={allCategoriesAreChecked}
                    variant="dot"
                    data-automation-id="AddCompetenceDialogFilterButtonBadge"
                  >
                    <FilterList />
                  </Badge>
                </IconButton>

                <Menu
                  id="category-context-menu"
                  anchorEl={contextMenuAnchorEl}
                  open={contextMenuIsOpen}
                  onClose={handleContextMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'category-context-menu-button',
                  }}
                  data-automation-id="AddCompetenceDialogFilterMenu"
                >
                  <MenuItem
                    onClick={() => setCheckedCategories(initialCategories || [])}
                    data-automation-id="AddCompetenceDialogFilterSelectAll"
                    disabled={categories?.length === checkedCategories.length}
                  >
                    {t('modals.AddCompetenceFilterCategoriesSelectAllButtonText')}
                  </MenuItem>
                  {categories?.map(({ properties }) => (
                    <MenuItem
                      key={properties?.groupId}
                      sx={{ py: 0 }}
                      onClick={handleToggleCategories(properties?.groupId)}
                      disabled={
                        checkedCategories.length === 1 &&
                        checkedCategories[0] === properties?.groupId
                      }
                    >
                      <FormControlLabel
                        sx={{ mr: 0, pointerEvents: 'none' }}
                        control={
                          <Switch
                            tabIndex={-1}
                            edge="end"
                            checked={checkedCategories.indexOf(properties?.groupId) !== -1}
                            sx={{ mx: 0 }}
                          />
                        }
                        label={properties?.groupName}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </>
            </ResponseHandler>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <ResponseHandler
          isLoading={availableCompetencesIsLoading}
          isError={availableCompetencesIsError}
          isEmpty={filteredData.filter((i) => i.items && i?.items?.length > 0).length === 0}
          EmptyComponent={<Typography>{t('modals.AddCompetenceNoDataText')}</Typography>}
          LoadingComponent={
            <Stack gap={2}>
              <Stack direction="row" gap={4}>
                <Skeleton variant="rounded" width={40} height={40} />
                <Skeleton variant="rounded" width="100%" height={40} />
              </Stack>
              <Stack direction="row" gap={4}>
                <Skeleton variant="rounded" width={40} height={40} />
                <Skeleton variant="rounded" width="100%" height={40} />
              </Stack>
              <Stack direction="row" gap={4}>
                <Skeleton variant="rounded" width={40} height={40} />
                <Skeleton variant="rounded" width="100%" height={40} />
              </Stack>
            </Stack>
          }
        >
          <Stack gap={2} component={List} disablePadding>
            {filteredData?.map((item) => {
              const { groupId, groupName, items } = item;
              if (items?.length === 0) return null;
              return (
                <ListItem disablePadding key={groupId}>
                  <FormControl component="fieldset" fullWidth>
                    <Stack gap={1}>
                      <Typography component="legend" variant="body2" fontWeight={600}>
                        {groupName}
                      </Typography>

                      <Box>
                        {items?.map(({ competenceId: itemId, competenceName: itemName }) => {
                          const labelId = `checkbox-list-label-${itemId}`;
                          const isChecked =
                            checkedCompetences.filter((i) => i.competenceId === itemId).length > 0;

                          return (
                            <AddCompetenceItem
                              key={`${groupId}-${itemId}`}
                              id={itemId}
                              labelId={labelId}
                              name={itemName ?? ''}
                              isChecked={isChecked}
                              initialLevel={initialValue}
                              handleToggleCompetences={() => handleToggleCompetences(itemId ?? 0)}
                              debouncedQuery={debouncedQuery}
                              setCheckedCompetence={setCheckedCompetences}
                              checkedCompetences={checkedCompetences}
                            />
                          );
                        })}
                      </Box>
                    </Stack>
                  </FormControl>
                </ListItem>
              );
            })}
          </Stack>
        </ResponseHandler>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          data-automation-id="AddCompetenceDialogCancelButton"
        >
          {t('modals.AddCompetenceButtonCancelButtonText')}
        </Button>
        <Button
          type="submit"
          disabled={checkedCompetences.length < 1}
          variant="contained"
          data-automation-id="AddCompetenceDialogAddButton"
        >
          {t('modals.AddCompetenceButtonSaveButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
