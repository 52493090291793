export const employeeCompetencesAvailableFullResult = {
  Entities: [
    {
      Properties: {
        GroupId: 2,
        GroupName: 'Project Management',
        CompetenceId: 2,
        CompetenceName: 'Prince2',
      },
    },
    {
      Properties: {
        GroupId: 1,
        GroupName: 'Language Skills',
        CompetenceId: 5,
        CompetenceName: 'Swedish',
      },
    },
    {
      Properties: {
        GroupId: 3,
        GroupName: 'Development',
        CompetenceId: 7,
        CompetenceName: '.NET',
      },
    },
    {
      Properties: {
        GroupId: 4,
        GroupName: 'Change Management Tools',
        CompetenceId: 11,
        CompetenceName: 'Change Management Mastering',
      },
    },
    {
      Properties: {
        GroupId: 1,
        GroupName: 'Language Skills',
        CompetenceId: 12,
        CompetenceName: 'Latvian',
      },
    },
    {
      Properties: {
        GroupId: 1,
        GroupName: 'Language Skills',
        CompetenceId: 13,
        CompetenceName: 'English',
      },
    },
  ],
  Links: [
    {
      Href: 'https://app8.timelog.com/npo_sandbox/api/v2/employee-competences/user/1/available',
      Rel: 'self',
    },
  ],
};
