import { IEntitiesResponse, IPropertiesResponse } from 'src/apis/types/responseBase';

export interface IEmployeeCompetences extends IPropertiesResponse<IEmployeeCompetencesProperties> {}

export interface IEmployeeCompetencesProperties {
  userId?: number;
  competenceList?: Competence[];
}

export interface Competence {
  groupName?: string;
  groupId?: number;
  items: CompetenceItem[];
}

interface CompetenceItem {
  id?: number;
  name?: string;
  value?: number;
}

export const EMPLOYEE_COMPETENCES_URL_PATH = '/api/v2/employee-competences';
export const EMPLOYEE_COMPETENCES_MSW_STATUS_KEY = 'employeeCompetencesAPI';
export const EMPLOYEE_AVAILABLE_COMPETENCES_MSW_STATUS_KEY = 'employeeAvailableCompetencesAPI';

export const GET_EMPLOYEE_COMPETENCES_KEY = 'EMPLOYEE_COMPETENCES';
export const GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY = 'EMPLOYEE_AVAILABLE_COMPETENCES';

export interface IEmployeeAvailableCompetences
  extends IEntitiesResponse<IEmployeeAvailableCompetencesProperties> {}

export interface IEmployeeAvailableCompetencesProperties {
  groupId?: number;
  groupName?: string;
  competenceId?: number;
  competenceName?: string;
}

export interface IConvertedAvailableCompetences {
  groupId?: number;
  groupName?: string;
  items?: IConvertedAvailableCompetencesItem[];
}

export interface IConvertedAvailableCompetencesItem {
  competenceId?: number;
  competenceName?: string;
}

export interface IAddCompetenceItems {
  competenceId?: number;
  value?: number;
}
