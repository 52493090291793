import { Link, useParams } from 'react-router-dom';
import ScreenHeader from 'src/components/layout/ScreenHeader';
import { Button } from 'src/components/mui-components';
import { Deck } from 'src/components/ui-components';
import PageRoute from 'src/routers/PageRoute';

export const Employee = () => {
  const { id } = useParams();

  return (
    <>
      <ScreenHeader />
      <PageRoute
        pageTitle={`Employee ${id}`}
        pageId="dc23305c-5d92-49ab-b67e-6e356218ff0b"
        pageIdentifier="Employee"
      >
        <Deck>
          <Deck.Item>
            <h2>Employee</h2>
            <Link to="competences">
              <Button component="span">Competences</Button>
            </Link>
          </Deck.Item>
        </Deck>
      </PageRoute>
    </>
  );
};
