import { useMemo, useState, MouseEvent } from 'react';
import {
  CheckCircle,
  Delete,
  Edit,
  GridOn,
  MoreVert,
  TableRows,
  UnpublishedOutlined,
} from '@mui/icons-material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { useGetEmployees } from 'src/apis/employeesAPI';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import {
  DataGrid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Stack,
  Select,
  ToggleButtonGroup,
  ToggleButton,
} from 'src/components/mui-components';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { IEmployeesProperties } from 'src/apis/employeesAPI/types';
import { Link } from 'react-router-dom';
import { Portal } from '@mui/base/Portal';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { SelectChangeEvent } from '@mui/material/Select';

const CellActions = ({ id }: { id: number }) => (
  <>
    <Link to={`/employees/${id}`}>
      <IconButton component="span" title="Edit" size="small">
        <Edit fontSize="small" />
      </IconButton>
    </Link>
    <IconButton title="Delete" size="small">
      <Delete fontSize="small" />
    </IconButton>
  </>
);

const HeaderActions = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={!!open}
        onClick={handleClick}
        title="More actions"
        size="small"
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Activate employees</MenuItem>
        <MenuItem onClick={handleClose}>Deactivate employees</MenuItem>
      </Menu>
      <IconButton title="Delete" size="small">
        <Delete fontSize="small" />
      </IconButton>
    </>
  );
};

const OutsideSearch = () => (
  <Portal container={() => document.getElementById('filter-panel')!}>
    <GridToolbarQuickFilter variant="outlined" size="small" />
  </Portal>
);

export const Employees = () => {
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { employees, isError, isLoading } = useGetEmployees();
  const { filterList } = useGetFilterAPI(pageIdentifier);

  const columns = useMemo<GridColDef<IEmployeesProperties>[]>(
    () => [
      {
        field: 'fullName',
        headerName: 'Employee',
        width: 250,
        renderCell: ({ row }) => `${row.fullName} (${row.initials})`,
      },
      { field: 'jobTitle', headerName: 'Job Title', width: 175 },
      { field: 'departmentName', headerName: 'Department', width: 175 },

      {
        field: 'managerInitials',
        headerName: 'Manager',
        width: 175,
        renderCell: ({ row }) => <abbr title={row.managerName}>{row.managerInitials}</abbr>,
      },
      { field: 'email', headerName: 'Email', width: 175 },
      { field: 'phone', headerName: 'Phone', width: 175, align: 'right', headerAlign: 'right' },
      { field: 'hourlyRate', headerName: 'Hourly rate', width: 200 },
      { field: 'internalCost', headerName: 'Internal cost', width: 200 },
      { field: 'normalWorkingTime', headerName: 'Normal working time', width: 200 },
      { field: 'salaryGroup', headerName: 'Salary group', width: 200 },
      { field: 'employeeType', headerName: 'Employee type', width: 200 },
      { field: 'publicHolidayCalendar', headerName: 'Public holiday calendar', width: 200 },
      {
        field: 'employmentStartDate',
        headerName: 'Employment start date',
        width: 200,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 70,
        renderCell: ({ row }) =>
          row.isActive ? (
            <Tooltip title="Is active">
              <CheckCircle color="success" />
            </Tooltip>
          ) : (
            <Tooltip title="Is not active">
              <UnpublishedOutlined color="error" />
            </Tooltip>
          ),
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'actions',
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => <CellActions id={params.row.id} />,
        renderHeader: () => <HeaderActions />,
        width: 80,
        align: 'center',
        headerAlign: 'center',
      },
    ],
    [],
  );
  const [viewStatus, setViewStatus] = useLocalStorage('employees-view-status', 'all');
  const [groupBy, setGroupBy] = useLocalStorage('employees-group-by', 'alphabetical');

  const handleViewChange = (event: SelectChangeEvent) => {
    setViewStatus(event.target.value as string);
  };
  const handleGroupByChange = (event: SelectChangeEvent) => {
    setGroupBy(event.target.value as string);
  };

  const [layout, setLayout] = useLocalStorage('employees-layout', 'table');

  const handleLayoutChange = (event: MouseEvent<HTMLElement>, newLayout: string) => {
    setLayout(newLayout);
  };

  return (
    <ResponseHandler isError={isError} isLoading={isLoading}>
      <FilterLayout filterList={filterList} localStorageNamePrefix="employees-overview">
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div id="filter-panel" />

            <Stack direction="row" gap={2}>
              <Stack direction="row">
                <Select
                  label="Status"
                  size="small"
                  value={viewStatus}
                  onChange={handleViewChange}
                  data-automation-id="EmployeesOverviewFilterStatus"
                >
                  <MenuItem value="all">All employees</MenuItem>
                  <MenuItem value="active">Active employees</MenuItem>
                  <MenuItem value="inActive">Inactive employees</MenuItem>
                </Select>
                <Select
                  label="Group by"
                  size="small"
                  value={groupBy}
                  onChange={handleGroupByChange}
                  data-automation-id="EmployeesOverviewGroupBy"
                >
                  <MenuItem value="alphabetical">Alphabetical</MenuItem>
                  <MenuItem value="department">Department</MenuItem>
                  <MenuItem value="license">License</MenuItem>
                  <MenuItem value="user-roles">User roles</MenuItem>
                </Select>
              </Stack>
              <Stack direction="row" gap={0}>
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  exclusive
                  value={layout}
                  onChange={handleLayoutChange}
                  aria-label="Layout"
                >
                  <ToggleButton value="table" tooltipProps={{ title: 'Table view' }}>
                    <TableRows />
                  </ToggleButton>
                  <ToggleButton tooltipProps={{ title: 'Grid view' }} value="grid">
                    <GridOn />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Stack>
          </Stack>
          <DataGrid
            columns={columns}
            rows={employees ?? []}
            pinnedColumns={{
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'fullName'],
              right: ['actions'],
            }}
            checkboxSelection
            disableRowSelectionOnClick
            slots={{ toolbar: OutsideSearch }}
          />
        </Stack>
      </FilterLayout>
    </ResponseHandler>
  );
};
